import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

enum Severity {
  error = 'error',
  info = 'info',
  warning = 'warning',
}

type ContainerProps = {
  severity: Severity;
  padding: string;
  border: boolean;
};

const SEVERITY_COLORS = {
  error: {
    background: `#FFF0F0`,
    border: `#D8C1C1`,
    text: `#8E5656`,
  },
  warning: {
    background: `#FFFDEB`,
    border: `#D8D5BA`,
    text: `#75725B`,
  },
  info: {
    background: `#EDF1FA`,
    border: `#083A7E`,
    text: `#083A7E`,
  },
};

export const Container = styled.div<ContainerProps>`
  background: ${({ severity }) => SEVERITY_COLORS[severity].background};
  border: ${({ border, severity }: ContainerProps) =>
    border ? `1px solid ${SEVERITY_COLORS[Severity[severity]].border}` : `none`}};
  border-radius: 8px;
  color: ${({ severity }) => SEVERITY_COLORS[severity].text};
  display: flex;
  font-size: 13px;
  line-height: ${20 / 13};
  padding: ${({ padding }) => padding};
  width: 100%;
`;

export const IconContainer = styled.div`
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 16px;
  margin-right: 10px;
`;

export const Content = styled.div<{ customStyles?: ReturnType<typeof css> }>`
  flex: 1 0 auto;
  max-width: calc(100% - 36px);

  strong {
    font-weight: bold;
  }

  ${({ customStyles }) => customStyles && customStyles}
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;

  a,
  button {
    background: none;
    border: none;
    color: currentColor;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    padding: 0;

    &:hover {
      color: currentColor;
    }
  }

  svg {
    display: block;
  }

  path {
    fill: currentColor;
  }
`;

export const CloseButton = styled.button<
  HTMLAttributes<HTMLButtonElement> & {
    hasCta: boolean;
  }
>`
  position: relative;

  /* Make click area a bit bigger */
  &::before {
    bottom: -12px;
    content: '';
    left: -12px;
    position: absolute;
    right: -12px;
    top: -12px;
  }

  /* If we also have a CTA add the vertical separator */
  ${({ hasCta }) =>
    hasCta &&
    css`
      margin-left: 32px;

      &::after {
        border-left: 1px solid currentColor;
        content: '';
        height: 16px;
        left: -16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;
